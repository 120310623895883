/** 活动列表 */
<template>
  <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
    <List :data="pastActivity" :loading="loading" :total="pageTotal" @page="changePage">
      <template v-slot="{ item }">
        <router-link :to="{ path: '/activity_news_details', query: { id: item.id } }" class="flex">
          <el-image class="item-img flex-1 border-r4" :src="$base + item.logo" fit="cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="item-info ml-20 flex-1 flex-column justify-around">
            <h4 :class="[
              'item-title',
              $isPc ? 'text-ellipsis-lines' : 'text-ellipsis',
            ]">
              {{ item.title }}
            </h4>
            <el-row class="activity-info-list">
              <el-col class="activity-info-item">
                <div v-if="$isPc" class="mb-20 text-ellipsis-lines" style="line-height: 24px;">
                  {{ $settleHtml(item.details) }}
                </div>
                <p class="item-info-desc">
                  <span> 发布时间：{{ item.release_at | filterTime }} </span>
                </p>
              </el-col>
            </el-row>
          </div>
        </router-link>
      </template>
    </List>
  </LoadMore>
</template>
<script>
import { getAllActivity } from '@/api/activity'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
export default {
  name: 'ActivityNews',
  components: {
    LoadMore,
    List,
  },
  data () {
    return {
      pageTotal: 0,
      pastActivity: [],
      activityOther: [], //相关文章
      loading: false, //正在加载
      finished: false, // 加载完成
      page: 1,
    }
  },
  mounted () {
    this.onLoadMore()
  },
  methods: {
    // 活动列表
    async getAllActivity (page) {
      try {
        let { data, total } = await getAllActivity({ page, type: '活动新闻' })
        this.pastActivity = this.$isPc ? data : [...this.pastActivity, ...data]
        this.pageTotal = total
        this.loading = false
      } catch (error) { }
    },
    // 切换分页
    changePage (page) {
      this.getAllActivity(page)
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.getAllActivity(this.page)
      done && done()
      if (this.pastActivity.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.page += 1
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .item {
  height: 150px;

  .item-info {
    width: 45%;
  }
}

@media (max-width: 767px) {
  ::v-deep .item {
    height: 100px;
  }
}
</style>
